@import "tailwindcss";

.img {
  width: 4rem;
  position: absolute;
  transition: opacity 150ms ease-in;
  opacity: 1;
  left: 0;
  top: 0;
}

.hide {
  opacity: 0;
}

.placeholder {
  width: 4rem;
  height: 4rem;
  visibility: hidden;
}

.fade-in {
  transition: opacity 150ms ease-in;
}
